import React, { useEffect } from 'react';
import styles from './FormDropdown.module.scss';
import Lock from '../Lock/Lock';

export default function FormDropdown({
  label,
  id,
  name,
  disabled,
  multiple,
  size,
  value,
  options,
  placeholder,
  onInput,
  required,
  readOnly,
  lockStatus,
  onLockClick,
  className,
  style,
  ...rest
}) {
  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById(id)?.shadowRoot) {
        const selectField = document?.getElementById(id)?.shadowRoot?.querySelector('select');
        if (placeholder && selectField && selectField.firstChild?.innerHTML !== placeholder) {
          const placeholderOpt = document.createElement('option');
          placeholderOpt.setAttribute('placeholder', '');
          placeholderOpt.classList.add('placeholder');
          placeholderOpt.value = null;
          placeholderOpt.innerHTML = placeholder;
          placeholderOpt.selected = value === undefined || value === null ? true : false;
          placeholderOpt.disabled = true;
          selectField.insertBefore(placeholderOpt, selectField.firstChild);
        }
      }
    }, 300);
  }, [id, placeholder, value]);

  return (
    <div className={`${styles.dropdownContainer} ${style?.dropdownContainer || ''}`}>
      {label && (
        <div className={`${styles.label} ${style?.label || ''}`}>
          {label} {required && <span className={styles.required}>*</span>}
        </div>
      )}
      <div className={`${styles.value} ${style?.value || ''}`}>
        {!readOnly && (
          <nsemble-select
            id={id}
            name={name}
            value={value}
            disabled={disabled}
            multiple={multiple}
            size={size}
            options={JSON.stringify(options)}
            onInput={onInput}
            class={className}
            {...rest}
          />
        )}
        {readOnly && <div>{value}</div>}
        <Lock
          lockStatus={lockStatus}
          onClick={onLockClick}
        />
      </div>
    </div>
  );
}
